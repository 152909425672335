










import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Main',
  props: {
    view: {
      type: String,
      default: 'lHh Lpr lFf',
    },
  },
  setup(props) {
    const getView = computed(() => {
      return props.view;
    });

    return { getView };
  },
});
