import Action from '@helpers/enums/template/action';
import { languageHsm } from '@helpers/utils';
import { Icommon } from '@models/general';

export const getFlagByLanguage = (language: string) => {
  const languagesFlags = languageHsm();
  const templateLanguage = language.split('_');
  let findedFlag = languagesFlags.find(
    languages =>
      languages.value === templateLanguage[0] || languages.metaFlag === language
  );
  if (findedFlag) {
    return findedFlag?.flag;
  } else if (templateLanguage.length > 1) {
    findedFlag = languagesFlags.find(
      languages =>
        languages.value === templateLanguage[1].toLowerCase() ||
        languages.metaFlag === language
    );
    return findedFlag?.flag;
  }
  return language;
};

export const transformFilterObject = (search: string): Icommon => {
  const REGEX_SEARCH_PARAMS = new RegExp('([a-zA-Z0-9_]+):"(.*?)"', 'gim');
  let match: RegExpExecArray | null = null;
  const searchOptions: Icommon = {};
  while ((match = REGEX_SEARCH_PARAMS.exec(search))) {
    const key = match[1] || '';
    const value = match[2] || '';
    searchOptions[key] = value;
  }

  const isEmpty = !Object.keys(searchOptions).length;

  return isEmpty
    ? { search, searchOptions: {} }
    : { searchOptions, search: '' };
};

export const transformInFilterString = (search: Icommon = {}): string => {
  const arraySearch: string[] = [];

  Object.keys(search).forEach(key => {
    const value = search[key] || '';
    arraySearch.push(`${key}:"${value}"`);
  });

  return arraySearch.join(' ');
};

export const checkPlaceHolders = (row: Icommon) => {
  const { message, interactives, placeholders, buttons } = row;
  const pattern = /{{(.*?)}}/g;

  const foundsInBody = parseInt(((message || '').match(pattern) || []).length);
  const foundsInList = parseInt(getVariableInList(interactives).length);
  const foundsInUrl = parseInt(getVariableInUrl(buttons).length);

  return foundsInBody + foundsInList + foundsInUrl === placeholders.length;
};

const getVariableInList = (interactives: Icommon) => {
  let tags: any = [];

  interactives?.sections?.forEach(section =>
    section.rows?.forEach(row => {
      if (row.title == null || row.title == '') {
        return;
      }

      const tagsRow = row?.title.match(/{{(.*?)}}/g) || [];
      tags = [...tags, ...tagsRow];
    })
  );

  return tags;
};

const getVariableInUrl = (buttons: Icommon) => {
  let tags: any = [];

  buttons.forEach(button => {
    if (button.type == Action.Url) {
      const tag = button?.url?.match(/{{(\d+)}}$/g) || [];
      tags = [...tags, ...tag];
    }
  });

  return tags;
};
export const translateAuthMessage = (
  languageCode,
  placeholder,
  hasAdvisory = false
) => {
  const translations = {
    sq: 'Përshëndetje, kodi juaj i vërtetimit është',
    bg: 'Здравейте, вашият код за автентикация е',
    zh_CN: '你好，您的验证码是',
    pt_BR: 'Olá, seu código de autenticação é',
    es: 'Hola, su código de autenticación es',
    en: 'Hello, your authentication code is',
    fr: "Bonjour, votre code d'authentification est",
    de: 'Hallo, Ihr Authentifizierungscode ist',
    el: 'Γεια σας, ο κωδικός επαλήθευσής σας είναι',
    it: 'Ciao, il tuo codice di autenticazione è',
    ja: 'こんにちは、あなたの認証コードは',
    sv: 'Hej, din autentiseringskod är',
    tr: 'Merhaba, doğrulama kodunuz',
    da: 'Hej, din godkendelseskode er',
    ko: '안녕하세요, 인증 코드가',
    ru: 'Здравствуйте, ваш код аутентификации',
    sr: 'Здраво, ваш код за аутентификацију је'
  };

  const message = translations[languageCode] || translations['pt_BR'];

  if (hasAdvisory) {
    const advisoryTranslations = {
      sq: 'Për sigurinë tuaj mos e ndani atë.',
      bg: 'За вашата безопасност не го споделяйте.',
      zh_CN: '为了您的安全，请勿分享。',
      pt_BR: 'Para sua segurança não o compartilhe.',
      es: 'Para su seguridad, no lo comparta.',
      en: 'For your security, do not share it.',
      fr: 'Pour votre sécurité, ne le partagez pas.',
      de: 'Zu Ihrer Sicherheit, teilen Sie ihn nicht.',
      el: 'Για την ασφάλειά σας, μην το μοιραστείτε.',
      it: 'Per la tua sicurezza, non condividerlo.',
      ja: '安全のため、共有しないでください。',
      sv: 'För din säkerhet, dela inte med dig av den.',
      tr: 'Güvenliğiniz için, paylaşmayın.',
      da: 'For din sikkerhed, del det ikke.',
      ko: '안전을 위해 공유하지 마십시오.',
      ru: 'Для вашей безопасности не делитесь им.',
      sr: 'Због ваше безбедности, не делите га.'
    };

    const advisoryMessage = advisoryTranslations[languageCode];

    return `${message} *${placeholder}*. ${advisoryMessage}`;
  }

  return `${message} *${placeholder}* .`;
};

export const translateAuthFooter = (languageCode, minutes) => {
  const translations = {
    sq: `Ky kod skadon për ${minutes} minuta.`,
    bg: `Този код изтича след ${minutes} минути.`,
    zh_CN: `该代码将在${minutes}分钟后过期。`,
    pt_BR: `Este código expira em ${minutes} minutos.`,
    es: `Este código expira en ${minutes} minutos.`,
    en: `This code expires in ${minutes} minutes.`,
    fr: `Ce code expire dans ${minutes} minutes.`,
    de: `Dieser Code läuft in ${minutes} Minuten ab.`,
    el: `Αυτός ο κωδικός λήγει σε ${minutes} λεπτά.`,
    it: `Questo codice scade tra ${minutes} minuti.`,
    ja: `このコードは${minutes}分で期限切れになります。`,
    sv: `Den här koden löper ut om ${minutes} minuter.`,
    tr: `Bu kod ${minutes} dakika içinde sona erecek.`,
    da: `Denne kode udløber om ${minutes} minutter.`,
    ko: `이 코드는 ${minutes} 분 후에 만료됩니다.`,
    ru: `Этот код истекает через ${minutes} минут.`,
    sr: `Овај код истиче за ${minutes} минута.`
  };

  return translations[languageCode] || translations['pt_BR'];
};
