





























import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  onBeforeMount
} from '@vue/composition-api';

import { LoadingBar } from 'quasar';

import { Iuser } from 'src/settings/models/model';
import sz_menu from 'src/helpers/menu';

import {
  AdminModule,
  SigninModule,
  SettingsModule,
  HsmModule
} from '@helpers/vuex-composition';
import sz_lang from '@helpers/translate';
import { languageList, getStorage } from '@helpers/utils';
import SzLayout from '@sz4/sz-components/src/stories/components/SzLayout/SzLayout.vue';
import useSwitcher, {
  IListSwitcherItem
} from '@sz4/sz-components/src/composables/useSwitcher';
import { IMenuItem } from '@sz4/sz-components/src/stories/components/SzMenuItem';
import { getUserCache } from '@helpers/cache';
import { legacySZ } from '@boot/axios';
import { szRoutes } from '@helpers/SzRoutes';

import { useFeatureFlag } from 'src/stories/composable/useFeatureFlag';

const { settings } = szRoutes;
import { $route } from 'src/router';

interface ISwitcherInfo {
  bsp: {
    hasOnboarding: boolean;
    isActive: boolean;
  };
  techprovider: {
    hasOnboarding: boolean;
    isActive: boolean;
  };
}

export default defineComponent({
  name: 'Layout',

  components: { SzLayout },

  setup() {
    const host = window.location.origin;

    const { isEnabled } = useFeatureFlag();
    const userData = getUserCache();

    const whitelabel = getStorage('sz_whitelabel');

    const {
      initUnleashClient,
      isReady,
      verifyModules,
      getFeatureFlag
    } = useSwitcher<'', 'bsp_partner'>({
      client_id: userData?.client_id,
      email: userData?.user.email,
      host: window.location.host,
      id: userData?.user._id
    });

    onBeforeMount(async () => {
      await initUnleashClient({
        appName: 'default',
        clientKey: '5W4itpGDwx1OsQTKO78xs6R1Rq8mYSqz',
        environment: 'production',
        url: 'https://unleash-proxy-xsvt6f6n2q-uc.a.run.app/proxy',
        refreshInterval: 60
      });

      checkAccess(settings.time_group.default, 'menu_config_timegroups');
      checkAccess(
        settings.administrators.default,
        'menu_config_administrators'
      );
    });

    onMounted(async () => {
      let modulesToVerify: Array<IListSwitcherItem<'bsp_partner'>> = ['voice'];

      let modulesToPush: Array<IListSwitcherItem<'bsp_partner'>> = [];

      const { bsp, techprovider }: ISwitcherInfo = (
        await legacySZ.get('/switcherInfo')
      ).data || {
        bsp: {
          hasOnboarding: false,
          isActive: false
        },
        techprovider: {
          hasOnboarding: false,
          isActive: false
        }
      };

      const isBSPEnabled = getFeatureFlag('bsp');

      if (isBSPEnabled) {
        if (
          !hasPartnerData.value ||
          (!hasPartnerData.value && !bsp.isActive) ||
          (hasPartnerData.value && bsp.hasOnboarding && bsp.isActive)
        ) {
          modulesToVerify.push('bsp');
        }

        if (
          hasPartnerData.value &&
          (!bsp.hasOnboarding ||
            (bsp.hasOnboarding && bsp.isActive) ||
            !techprovider.isActive ||
            (techprovider.hasOnboarding && techprovider.isActive))
        ) {
          modulesToPush.push({
            img: whitelabel.logo_tech_provider,
            module_id: 'bsp_partner',
            name: whitelabel.system_product,
            to: `${window.location.origin}/techprovider/signin/?lang=${window.navigator.language}`
          });
        }
      }

      state.modules = state.modules.concat(
        verifyModules(modulesToVerify),
        modulesToPush
      );
    });

    function checkAccess(routeName: string, flag: string) {
      const route = window.location.pathname;
      if (route.includes(routeName) && !isEnabled(flag)) {
        void $route.replace({ name: '404' });
      }
    }

    // DATA
    const start = ref(true);

    const { translate } = sz_lang();

    const state = reactive({
      listMenu: [] as IMenuItem[],
      modules: [] as IListSwitcherItem<'bsp_partner'>[]
    });

    // STATE
    const { announcement } = SettingsModule.useState(['announcement']);

    const { existsChannelsNotDialog } = HsmModule.useState([
      'existsChannelsNotDialog'
    ]);

    // ACTIONS
    const { GET_CHECK_CHANNEL_NOT_DIALOG } = HsmModule.useActions([
      'GET_CHECK_CHANNEL_NOT_DIALOG'
    ]);

    const { LOGOUT } = SigninModule.useActions(['LOGOUT']);

    const { CLOSE_ANNOUNCEMENT } = SettingsModule.useActions([
      'CLOSE_ANNOUNCEMENT'
    ]);

    // GETTERS
    const { user } = AdminModule.useGetters(['user']);

    const {
      visibleAnnouncement,
      colorAnnouncement
    } = SettingsModule.useGetters(['visibleAnnouncement', 'colorAnnouncement']);

    onMounted(async () => {
      await GET_CHECK_CHANNEL_NOT_DIALOG();

      loadTagManager();
    });

    // loading page
    LoadingBar.start();

    const interval = setInterval(() => {
      let value = document.readyState === 'complete' ? false : true;
      start.value = value;
    }, 500);

    watch(
      () => start.value,
      () => {
        clearInterval(interval);
        LoadingBar.stop();
      }
    );

    // COMPUTEDS
    const hasPartnerData = computed(
      () =>
        !!whitelabel &&
        !!whitelabel.logo_tech_provider &&
        !!whitelabel.bsp_partner_id
    );

    const languages = computed(() => {
      return languageList();
    });

    const getListMenu = computed(() => {
      return sz_menu(existsChannelsNotDialog);
    });

    const getProfile = computed(() => {
      return {
        email: user.value.email,
        name: user.value.name,
        src: user.value.photo
      } as Iuser;
    });

    // FUNCTIONS
    function loadTagManager() {
      if (whitelabel.default === false) {
        return null;
      }

      const tagManagerScript = document.createElement('script');
      tagManagerScript.textContent =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5DRDD4G');";
      document.head.appendChild(tagManagerScript);
      let noscriptTagManager = document.createElement('noscript');
      let ifrmTagManager = document.createElement('iframe');
      ifrmTagManager.setAttribute(
        'src',
        'https://www.googletagmanager.com/ns.html?id=GTM-5DRDD4G'
      );
      ifrmTagManager.style.width = '0';
      ifrmTagManager.style.height = '0';
      ifrmTagManager.style.display = 'none';
      ifrmTagManager.style.visibility = 'hidden';
      noscriptTagManager.appendChild(ifrmTagManager);
      document.body.prepend(noscriptTagManager);
    }

    function profileRedirect() {
      location.href = '/profile';
    }

    return {
      host,
      isReady,
      languages,
      translate,
      whitelabel,
      visibleAnnouncement,
      getListMenu,
      getProfile,
      announcement,
      colorAnnouncement,
      CLOSE_ANNOUNCEMENT,
      LOGOUT,
      ...toRefs(state),
      profileRedirect
    };
  }
});
